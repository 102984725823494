import React, { useState , useRef, useEffect } from 'react';
import { useLoadEffect } from 'sana/utils';
import PropTypes from 'prop-types';
import { Link } from 'sana/elements';
import { UseSanaTexts } from 'sana/texts';
import { formatCategoryData } from '../../utils';
import styles from './../ProductSubcategory.module.scss';
import ActiveOnFocusButton from '../../utils/ActiveOnFocusButton/ActiveOnFocusButton';

const ContentWrapper = ({ data, subMenuList, setShowCategory }) => {
  const [naviData, setNaviData] = useState([]);
  const [tabIndex, setTabIndex] = useState(-1);
  const [activeOnFocusButtonTextKey, setActiveOnFocusButtonTextKey] = useState('ShowSubMenuList');
  let inputRef = useRef(null);

  useLoadEffect(() => {
    if (data.children) {
      const subList = formatCategoryData(data.children, subMenuList);
      setNaviData(subList);
    } else 
      setNaviData([]);
  }, [data.children, subMenuList]);

  const expanSubList = () => {
    setShowCategory(prev => {    
      if (!prev) 
        setTabIndex(0);
       else 
        setTabIndex(-1);  
      return !prev;
    });
  };

  useEffect(()=>{
    if(tabIndex === 0)
    { 
      setTimeout(() => {
        inputRef && inputRef.focus(); 
        setTimeout(() => {
          setActiveOnFocusButtonTextKey('HideSubMenuList');
        }, 1000); 
      }, 1000); 
    } 
    else
      setActiveOnFocusButtonTextKey('ShowSubMenuList');   
  },[inputRef, tabIndex]);  

  const setRefarance = (index, ref)=>{
    if(index === 0){
      inputRef = ref;
    }
  };

  const handleBlur = event => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setShowCategory(false);
      setTabIndex(-1);
    }
  };

  return (        
  <UseSanaTexts textKeys={['MainCategory', 'Subcategory']}>{
    ([MainCategory, Subcategory])=>(
    <div className={styles.contentWrapper} onBlur={handleBlur}>
      <div className={styles.mainMenu}>{
            data.link ? (
            <Link
              to={data.link.to}
              url={data.link.url}
              className={styles.title}
              aria-label={`${MainCategory}: ${data.title}`}
            >
              {data.title}
            </Link>):
            (<div className={styles.title} aria-label={`${MainCategory}: ${data.title}`}>{data.title}</div>)
        }
      </div>
      {naviData.length > 0 && (
        <>
          <ActiveOnFocusButton textKey={activeOnFocusButtonTextKey} onClick={expanSubList} />
          <div className={styles.subcategoryWrapper}>
            <ul>
              {naviData.map((subItem, index) => (
                <li key={subItem.id}>
                  {subItem.link ? (
                    <Link   
                      tabIndex={tabIndex}
                      to={subItem.link.to}
                      url={subItem.link.url}
                      aria-label={`${Subcategory}: ${subItem.title}`}
                      ref={ref => {setRefarance(index, ref);}}
                    >
                      {subItem.title}
                    </Link>
                  ) : (
                    <Link 
                      tabIndex={tabIndex}
                      className={styles.disbled} 
                      aria-label={`${Subcategory}: ${subItem.title}`} 
                      ref={ref => {setRefarance(index, ref);}}
                    >{subItem.title}</Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>)}
  </UseSanaTexts>
  );
};

ContentWrapper.propTypes = {
  data: PropTypes.object.isRequired,
  subMenuList: PropTypes.array.isRequired,
  setShowCategory: PropTypes.func.isRequired,
};

export default React.memo(ContentWrapper);
