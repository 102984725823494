 import { isTouchDevice as _isTouchDevice } from './helper.scss';
 import { useSsrLoad } from './ssr';

export const formatCategoryData = (navigationData, subMenuList) => {
  const uniqueResultOne = navigationData.map(function (naviDataObject) {
    const item = subMenuList.find(function (menuObj) {
      return menuObj.menuId === naviDataObject.id;
    });
    return { visible: true, ...naviDataObject, ...item, menuId: naviDataObject.id };
  });

  const final = uniqueResultOne
    .filter(unicItem => unicItem.visible)
    .map(unicItem => {
      const obj = navigationData.find(item => unicItem.menuId === item.id);
      return { ...unicItem, ...obj };
    });
    final.sort(function(a, b) {
      return a.displayIndex - b.displayIndex;
    });
  return final;
};

export const getImageScalingClass = imageScalingOption => {
  switch (imageScalingOption) {
    case 0:
      return 'image-scale-based-on-width';
    case 1:
      return 'image-scale-based-on-height';
    case 2:
      return 'image-scale-do-not-scale';
    case 3:
      return 'image-scale-best-view';
    default:
      return '';
  }
};

export function useIsTouchDevice() {
  let isTouchDevice = false;
  const ssrLoad = useSsrLoad();
  if(!ssrLoad){
    isTouchDevice = window?.matchMedia(_isTouchDevice).matches;
  }
  return isTouchDevice;
}