import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useLoadEffect } from 'sana/utils';
import Image from './image/Image';
import ContentWrapper from './content/ContentWrapper';
import { requestNavigationData } from '../behavior/actions';
import styles from './ProductSubcategory.module.scss';
import { useIsTouchDevice } from '../utils';
import { SimpleText } from 'sana/texts';

const ProductSubcategory = ({ model, id: contentBlockId, isDesignerMode }) => {
  if (!model) 
    return null;
  const dispatch = useDispatch();
  const { navigationData } = useSelector(state => state);
  const { menuId, categoryImage, subMenuList } = model;
  const isTouchDevice = useIsTouchDevice();
  const [showCategory, setShowCategory] = useState(isTouchDevice);
  const elementId = `product-subcategory-content-block-${contentBlockId}`;
  
  useLoadEffect(() => {
    dispatch(requestNavigationData(menuId, contentBlockId));
  }, [menuId]);

  return (
    <div
      id={elementId}
      className={classNames(
        styles.productCategoryWrapper,
        (isTouchDevice || showCategory) && styles.showCategory,
        styles.squareStyle,
        isDesignerMode && styles.designerMode,
      )}
    > 
      {navigationData && navigationData[contentBlockId] ? (      
        <>
          <span role="presentation" tabIndex={0} className={styles.visuallyHidden}>{model.contentElementAltText ? model.contentElementAltText :  <SimpleText textKey={'ContentElementAltText'} />}</span>
          <Image
            imagePath={
              categoryImage
                ? categoryImage
                : navigationData[contentBlockId].image.path
            }
            imageAltText={navigationData[contentBlockId].title}
            data={navigationData[contentBlockId]}
            imageScalingOption={model.imageScalingOption}
          />
          <ContentWrapper
            data={navigationData[contentBlockId]}
            subMenuList={subMenuList}
            setShowCategory ={setShowCategory}
          />
        </>   
      ) : (
        <div className={styles.placeholder} />
      )}
    </div>
  );
};

ProductSubcategory.propTypes = {
  model: PropTypes.object,
  id: PropTypes.string,
  isDesignerMode: PropTypes.bool,
};

export default React.memo(ProductSubcategory);
