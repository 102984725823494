exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\1.0.40.16\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, "", ""]);

// exports
exports.locals = {
	"isTouchDevice": "(pointer:coarse)",
	"isTouchScreen": "(pointer:fine) and (any-pointer:coarse) and (any-pointer:fine)"
};