import { useState, useEffect } from 'react';

const useSsrLoad = () => {
  const [isSsrLoad, setSsrLoad] = useState(true);
  useEffect(() => {
    setSsrLoad(false);
  }, []);
  return isSsrLoad;
};
export { useSsrLoad };
