exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\1.0.40.16\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".ProductSubcategoryContentBlock__ActiveOnFocusButton_visually-hidden{clip:rect(0,0,0,0);border:0;height:0;margin:-1px;overflow:hidden;padding:0;position:absolute;width:0}.ProductSubcategoryContentBlock__ActiveOnFocusButton_active-on-focus:focus{clip:auto;height:auto;max-height:calc(100% - 10px);outline:0;overflow:auto;padding:5px 10px;white-space:nowrap;width:auto}.ProductSubcategoryContentBlock__ActiveOnFocusButton_active-on-focus.ProductSubcategoryContentBlock__ActiveOnFocusButton_topLeft{left:5px;top:5px}.ProductSubcategoryContentBlock__ActiveOnFocusButton_active-on-focus.ProductSubcategoryContentBlock__ActiveOnFocusButton_topRight{right:5px;top:5px}.ProductSubcategoryContentBlock__ActiveOnFocusButton_active-on-focus.ProductSubcategoryContentBlock__ActiveOnFocusButton_bottomLeft{bottom:5px;left:5px}.ProductSubcategoryContentBlock__ActiveOnFocusButton_active-on-focus.ProductSubcategoryContentBlock__ActiveOnFocusButton_bottomRight{bottom:5px;right:5px}", ""]);

// exports
exports.locals = {
	"visually-hidden": "ProductSubcategoryContentBlock__ActiveOnFocusButton_visually-hidden",
	"visuallyHidden": "ProductSubcategoryContentBlock__ActiveOnFocusButton_visually-hidden",
	"active-on-focus": "ProductSubcategoryContentBlock__ActiveOnFocusButton_active-on-focus",
	"activeOnFocus": "ProductSubcategoryContentBlock__ActiveOnFocusButton_active-on-focus",
	"topLeft": "ProductSubcategoryContentBlock__ActiveOnFocusButton_topLeft",
	"topRight": "ProductSubcategoryContentBlock__ActiveOnFocusButton_topRight",
	"bottomLeft": "ProductSubcategoryContentBlock__ActiveOnFocusButton_bottomLeft",
	"bottomRight": "ProductSubcategoryContentBlock__ActiveOnFocusButton_bottomRight"
};